import { FetchResult } from "@apollo/client";
import {
  Classes,
  FormGroup,
  Intent,
  PopoverInteractionKind,
} from "@blueprintjs/core";
import {
  DOCS_LINKS,
  HexColor,
  IconType,
  StatusId,
  sentenceCase,
} from "@hex/common";
import React, { useCallback, useMemo, useState } from "react";
import styled from "styled-components";

import {
  HexButton,
  HexDialog,
  HexSwitch,
  HexTextArea,
  HexTooltip,
  IconPicker,
  Txt,
} from "../../../hex-components";
import { HexInputGroupWithErrorTooltip } from "../../../hex-components/HexInput";
import { CharacterCountIndicator } from "../../common/CharacterCountIndicator";
import { DocsLink } from "../../common/DocsLink.js";
import { DropdownFormField } from "../../common/form/field/DropdownFormField";
import { CategoryLabel } from "../../common/labels/CategoryLabel";
import { StatusLabel } from "../../common/labels/StatusLabel";
import { useToaster } from "../../common/Toasts";
import { useFeatureGates } from "../../feature-gate/FeatureGateContext.js";
import {
  FeatureGatePill,
  FeatureGateWrapper,
} from "../../feature-gate/FeatureGatePill.js";
import { FeatureGateToolTip } from "../../feature-gate/FeatureGateToolTip.js";
import { InputBooleanConfiguration } from "../../input/config/InputBooleanConfiguration";

import {
  LabelPanelCreateItemParams,
  LabelPanelUpdateItemParams,
} from "./LabelPanelContainer";

const NAME_MAX_LENGTH = 30;
const DESCRIPTION_MAX_LENGTH = 200;

export interface LabelFormDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onCreateItem?: (params: LabelPanelCreateItemParams) => Promise<FetchResult>;
  onUpdateItem?: (
    params: LabelPanelUpdateItemParams<string>,
  ) => Promise<FetchResult>;
  itemTypeName: ItemTypeName;
  initialForm?: Form;
  defaultStatusId?: StatusId | null;
  existingItemNames: Set<string>;
  // We intend to deprecate "inLibrary" statuses, so only show the config
  // to orgs that have library statuses that have not been converted to endorsements.
  showLibraryConfig?: boolean;
  magicAllowed?: boolean;
}

type ItemTypeName = "status" | "category";

type Form = {
  id?: string;
  name: string;
  color?: string;
  description: string;
  // Status-specific below
  inLibrary?: boolean;
  endorsed?: boolean;
  enforcesReview?: boolean;
  icon?: IconType | null;
};

const COLOR_OPTIONS = [
  HexColor.RED,
  HexColor.ORANGE,
  HexColor.YELLOW,
  HexColor.LIME,
  HexColor.GREEN,
  HexColor.TEAL,
  HexColor.BLUE,
  HexColor.PURPLE,
  HexColor.PINK,
  HexColor.GRAY,
];

export const LabelFormDialog: React.ComponentType<LabelFormDialogProps> =
  React.memo(function LabelFormDialog({
    defaultStatusId,
    existingItemNames,
    initialForm,
    isOpen,
    itemTypeName,
    magicAllowed,
    onClose,
    onCreateItem,
    onUpdateItem,
    showLibraryConfig,
  }) {
    const isStatusItemType = itemTypeName === "status";

    const { endorsements: endorsementsNotGated } = useFeatureGates();

    const toaster = useToaster();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [form, setForm] = useState<Form>(
      initialForm ?? {
        name: "",
        color: undefined,
        inLibrary: isStatusItemType ? false : undefined,
        description: "",
        icon: null,
      },
    );

    const isDefaultStatus = form.id === defaultStatusId;

    const colorOptions = useMemo(() => {
      return COLOR_OPTIONS.map((color) => ({
        value: color,
        label: isStatusItemType ? (
          <StatusLabel
            status={{
              color,
              name: sentenceCase(color) as string,
              description: null,
              endorsed: false,
              icon: null,
            }}
          />
        ) : (
          <CategoryLabel
            category={{
              color,
              name: sentenceCase(color) as string,
              description: null,
            }}
          />
        ),
      }));
    }, [isStatusItemType]);

    const submitForm = useCallback(async () => {
      if (!isValidForm(form, isStatusItemType)) {
        return;
      }

      setIsSubmitting(true);
      try {
        if (form.id == null) {
          await onCreateItem?.({
            name: form.name ?? "",
            color: form.color ?? "",
            description: form.description ?? "",
            inLibrary: form.inLibrary ?? undefined,
            endorsed: form.endorsed ?? undefined,
            enforcesReview: form.enforcesReview ?? undefined,
            icon: form.icon ?? undefined,
          });
        } else {
          await onUpdateItem?.({
            id: form.id,
            name: form.name ?? "",
            color: form.color ?? "",
            description: form.description ?? "",
            inLibrary: form.inLibrary ?? undefined,
            endorsed: form.endorsed ?? undefined,
            enforcesReview: form.enforcesReview ?? undefined,
            icon: form.icon ?? undefined,
          });
        }
      } catch (e) {
        console.error(e);
        toaster.show({
          message: `Failed to save ${itemTypeName}. ${e}`,
          intent: Intent.DANGER,
        });
        setIsSubmitting(false);
        return;
      }

      onClose();
      setIsSubmitting(false);
    }, [
      form,
      isStatusItemType,
      itemTypeName,
      onClose,
      onCreateItem,
      onUpdateItem,
      toaster,
    ]);

    const {
      onColorChange,
      onDescriptionChange,
      onEndorsedChange,
      onEnforcesReviewChange,
      onIconChange,
      onInLibraryChange,
      onNameChange,
    } = useMemo(() => {
      return {
        onNameChange: (e: React.ChangeEvent<HTMLInputElement>) => {
          const name = e.currentTarget.value;
          setForm((oldForm) => ({
            ...oldForm,
            name,
          }));
        },
        onColorChange: (color: string) =>
          setForm((oldForm) => ({
            ...oldForm,
            color,
          })),
        onDescriptionChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => {
          const description = e.currentTarget.value;
          setForm((oldForm) => ({
            ...oldForm,
            description,
          }));
        },
        onInLibraryChange: (inLibrary: boolean) =>
          setForm((oldForm) => ({
            ...oldForm,
            inLibrary,
          })),
        onEndorsedChange: (evt: React.FormEvent<HTMLInputElement>) => {
          const prevEnforcesReview = form.enforcesReview;
          const prevIcon = form.icon;
          const newValue = evt.currentTarget.checked;

          setForm((oldForm) => ({
            ...oldForm,
            endorsed: newValue,
            // Only endorsed statuses can enforce reviews
            enforcesReview: newValue ? prevEnforcesReview : false,
            // Only endorsed statuses can have an icon
            icon: newValue ? prevIcon : null,
          }));
        },
        onEnforcesReviewChange: (evt: React.FormEvent<HTMLInputElement>) =>
          setForm((oldForm) => ({
            ...oldForm,
            enforcesReview: evt.currentTarget.checked,
          })),
        onIconChange: (icon: IconType) =>
          setForm((oldForm) => ({
            ...oldForm,
            icon,
          })),
      };
    }, [form.enforcesReview, form.icon]);

    const endorsementSwitch = useMemo(() => {
      if (endorsementsNotGated && isDefaultStatus) {
        return (
          <HexTooltip
            content="The default status cannot be endorsed."
            hoverCloseDelay={100}
            interactionKind={PopoverInteractionKind.HOVER}
          >
            <HexSwitch alignIndicator="right" disabled={true} />
          </HexTooltip>
        );
      } else {
        return (
          <HexSwitch
            alignIndicator="right"
            checked={Boolean(form.endorsed)}
            disabled={!endorsementsNotGated}
            labelElement={<>{!endorsementsNotGated && <FeatureGatePill />}</>}
            onChange={onEndorsedChange}
          />
        );
      }
    }, [
      endorsementsNotGated,
      form.endorsed,
      isDefaultStatus,
      onEndorsedChange,
    ]);

    const title = `${form.id == null ? "New" : "Update"} ${itemTypeName}`;
    const isItemNameTaken = existingItemNames.has(form.name);

    return (
      <StyledDialog
        isCloseButtonShown={true}
        isOpen={isOpen}
        title={title}
        onClose={onClose}
      >
        <div className={Classes.DIALOG_BODY}>
          <form action="submit">
            <FormRow>
              <FormGroup css="flex: 2" label="Name" labelInfo="(required)">
                <HexInputGroupWithErrorTooltip
                  error={
                    isItemNameTaken
                      ? `A ${itemTypeName} with this name already exists`
                      : undefined
                  }
                  fill={true}
                  maxLength={NAME_MAX_LENGTH}
                  value={form.name}
                  onChange={onNameChange}
                />
                <NameCharacterCountIndicator
                  count={form.name.length}
                  max={NAME_MAX_LENGTH}
                />
              </FormGroup>
              <FormGroup css="flex: 1" label="Color" labelInfo="(required)">
                <DropdownFormField
                  options={colorOptions}
                  value={form.color}
                  onChange={onColorChange}
                />
              </FormGroup>
            </FormRow>
            <FormGroup
              css={`
                position: relative;
              `}
              label="Description"
            >
              <HexTextArea
                fill={true}
                value={form.description}
                onChange={onDescriptionChange}
              />
              <DescriptionCharacterCountIndicator
                count={form.description.length}
                max={DESCRIPTION_MAX_LENGTH}
              />
            </FormGroup>
            {isStatusItemType && (
              <>
                <FormGroup>
                  <FeatureGateToolTip
                    content={<>use endorsed statuses.</>}
                    disabled={endorsementsNotGated}
                    featureGate="endorsements"
                  >
                    <FeatureGateWrapper>
                      <FormRow>
                        <Txt>Endorsed status</Txt>
                        {endorsementSwitch}
                      </FormRow>
                    </FeatureGateWrapper>
                  </FeatureGateToolTip>
                  <Txt fontColor="muted">
                    These statuses indicate that a project, component, or data
                    has been endorsed for wider use.{" "}
                    {magicAllowed
                      ? "Endorsed data is prioritized for usage with Magic. "
                      : ""}
                    They can only be applied by Admins and Workspace Managers.{" "}
                    <DocsLink to={DOCS_LINKS.Endorsements}>Learn more</DocsLink>
                  </Txt>
                </FormGroup>
                {form.endorsed && (
                  <EndorsedDetails>
                    <FormGroup>
                      <HexSwitch
                        alignIndicator="right"
                        checked={Boolean(form.enforcesReview)}
                        labelElement={
                          <Txt fontSize="small">Enforces reviews</Txt>
                        }
                        onChange={onEnforcesReviewChange}
                      />
                      <Txt fontColor="muted">
                        Once this status is applied to a project or component,
                        changes must be reviewed before publishing.{" "}
                        <DocsLink to={DOCS_LINKS.Reviews}>Learn more</DocsLink>
                      </Txt>
                    </FormGroup>
                    <FormGroup label="Icon">
                      <IconPicker
                        selectedIcon={form.icon ?? IconType.chexagon}
                        onPick={onIconChange}
                      />
                    </FormGroup>
                  </EndorsedDetails>
                )}
              </>
            )}
            {form.inLibrary != null && showLibraryConfig && (
              <FormGroup>
                <HexTooltip
                  content={
                    "If enabled, apps published with this status will appear in your workspace's Library. Endorsed statuses will always appear in the Library."
                  }
                  disabled={!form.endorsed}
                >
                  <InputBooleanConfiguration
                    disabled={form.endorsed}
                    info={
                      "If enabled, apps published with this status will appear in your workspace's Library."
                    }
                    label="In Library"
                    value={form.inLibrary || form.endorsed}
                    onChange={onInLibraryChange}
                  />
                </HexTooltip>
              </FormGroup>
            )}
          </form>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <Preview>
            <Txt fontSize="small">Preview</Txt>
            {isStatusItemType ? (
              <StatusLabel
                status={{
                  color: (form.color as HexColor) ?? HexColor.RED,
                  name: form.name === "" ? "New Status" : form.name,
                  description: form.description,
                  endorsed: form.endorsed ?? false,
                  icon: form.icon ?? null,
                }}
              />
            ) : (
              <CategoryLabel
                category={{
                  color: (form.color as HexColor) ?? HexColor.RED,
                  name: form.name === "" ? "New Category" : form.name,
                  description: form.description,
                }}
              />
            )}
          </Preview>
          <HexButton
            disabled={
              isSubmitting ||
              !isValidForm(form, isStatusItemType) ||
              isItemNameTaken
            }
            intent={Intent.SUCCESS}
            loading={isSubmitting}
            onClick={submitForm}
          >
            {title}
          </HexButton>
        </div>
      </StyledDialog>
    );
  });

function isValidForm(form: Form, isStatusItemType: boolean): boolean {
  if (form.name.trim().length === 0 || form.name.length > NAME_MAX_LENGTH) {
    return false;
  }
  if (form.color == null) {
    return false;
  }
  if (form.description.length > DESCRIPTION_MAX_LENGTH) {
    return false;
  }
  if (isStatusItemType && form.inLibrary == null) {
    return false;
  }
  return true;
}

const StyledDialog = styled(HexDialog)`
  width: 500px;

  .${Classes.DIALOG_BODY} {
    margin-bottom: 0;
    font-size: ${({ theme }) => theme.fontSize.SMALL};
  }

  .${Classes.DIALOG_FOOTER} {
    display: flex;
    justify-content: space-between;
  }

  textarea {
    min-height: 100px;
  }
`;

const NameCharacterCountIndicator = styled(CharacterCountIndicator)`
  margin-top: 2px;
  float: right;
`;

const DescriptionCharacterCountIndicator = styled(CharacterCountIndicator)`
  position: absolute;
  right: 0px;
  bottom: -24px;
`;

const FormRow = styled.div`
  display: flex;
  gap: 16px;
  justify-content: space-between;
  width: 100%;
`;

const EndorsedDetails = styled.div`
  padding-left: 10px;
  border-left: 1px solid ${({ theme }) => theme.borderColor.DEFAULT};
`;

const Preview = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;
